import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  Segment,
  Grid,
  Header,
  Icon,
  List,
  Divider,
  Popup,
} from "semantic-ui-react";
import { Airline } from "src/components";
import { getFlightStatus } from "src/services/application";
import { formatDate, formatTime, toSpinalCase } from "src/common";
import { useFocusRefOnLoad } from "src/common/hooks";
import { FlightStatusEnumI } from "src/redux/reducers/application";
import { Passenger } from "src/types/Passenger";
import PlaneIcon from "src/icons/PlaneIcon";
import EmailIcon from "src/icons/EmailIcon";
import { FlightSegment } from "src/types/Flight";

const riskToClass = {
  1: "lowestRisk",
  2: "lowRisk",
  3: "mediumRisk",
  4: "highRisk",
  5: "severeRisk",
};

type City = {
  airportCode: string;
  state: string;
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  utcTime: string;
  localTime: string;
  localEstimatedTime: string;
  highestRiskLevel?: number;
};

const PopupContent = ({
  departureCity,
  arrivalCity,
  passengers,
  setOpen,
}: {
  departureCity: City;
  arrivalCity: City;
  passengers: Passenger[];
  setOpen: (val: boolean) => void;
}) => {
  const emailRef = useFocusRefOnLoad();
  const containerRef = useRef(null);

  useEffect(() => {
    function listener({ relatedTarget }) {
      if (
        containerRef.current &&
        !containerRef.current.contains(relatedTarget)
      ) {
        setOpen(false);
      }
    }
    window.addEventListener("focusout", listener, true);
    return () => window.removeEventListener("focusout", listener);
  }, [setOpen]);

  let emailFound;

  const getEmail = (p) => {
    if (p.email) {
      const ref = emailFound ? null : emailRef;
      emailFound = true;
      return (
        <a href={`mailto:${p.email}`} ref={ref}>
          <EmailIcon width="12px" />
        </a>
      );
    } else return null;
  };
  return (
    <div className="todays-flights-popup" ref={containerRef}>
      <h4>
        {departureCity.city} To {arrivalCity.city}
      </h4>
      <ul>
        {passengers.map((p, i) => (
          <li key={p.name} data-aut={`FlightCard|Popup|Passenger${i}`}>
            {p.name} {getEmail(p)}
          </li>
        ))}
      </ul>
    </div>
  );
};

const FlightPopup = ({
  departure,
  arrival,
  passengers,
}: {
  departure: City;
  arrival: City;
  passengers: Passenger[];
}) => {
  const [isOpen, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  return (
    <Popup
      trigger={
        <button className="unstyled-button" title="Traveler List">
          <Icon name="travel" aria-label="Traveler Popup Icon" />
        </button>
      }
      on="click"
      open={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      position="left center"
    >
      <Popup.Content>
        <PopupContent
          departureCity={departure}
          arrivalCity={arrival}
          passengers={passengers}
          setOpen={setOpen}
        />
      </Popup.Content>
    </Popup>
  );
};

const renderRisk = (rating: number) => {
  let riskText, riskClass;
  switch (rating) {
    case 5:
      riskText = "Extreme";
      riskClass = "extreme";
      break;
    case 4:
      riskText = "High";
      riskClass = "high";
      break;
    case 3:
      riskText = "Moderate";
      riskClass = "moderate";
      break;
    case 2:
      riskText = "Low";
      riskClass = "low";
      break;
    case 1:
      riskText = "Trivial";
      riskClass = "trivial";
      break;
    default:
      return;
  }
  return (
    <List.Item
      data-aut="FlightCard|FlightRisk"
      className={`risk-text ${riskClass}`}
    >
      <span className={`risk-text extreme`}>Risk:</span> {riskText}
    </List.Item>
  );
};

interface FlightCardI {
  operatingFlightNumber: string;
  carrier: string;
  arrival: City;
  departure: City;
  displayRisk?: boolean;
  passengers?: Passenger[];
  isRiskCard?: boolean;
  isSelected?: boolean;
  flight: FlightSegment;
  passenger: Passenger;
  renderPopup?: boolean;
  flightStatusEnum: FlightStatusEnumI;
}

const FlightCard = ({
  operatingFlightNumber,
  carrier,
  arrival,
  departure,
  displayRisk,
  passengers,
  isRiskCard,
  isSelected,
  flight,
  passenger,
  renderPopup,
  flightStatusEnum,
}: FlightCardI) => {
  const getStatus = (flight) => {
    const statusLabel = getFlightStatus(flight.flightStatus, flightStatusEnum);
    const status = toSpinalCase(statusLabel);
    return (
      <List.Item
        data-aut="FlightCard|FlightStatus"
        className={`status ${status}`}
      >
        {statusLabel}
        <br />
      </List.Item>
    );
  };

  const renderPassengers = () => {
    return passenger
      ? passenger.name
      : passengers.map((p) => p.name).join(", ");
  };

  const flightCardClass = (isRiskCard && riskToClass[flight.riskLevel]) || "";
  let riskRating = 0;
  if (displayRisk) {
    if (departure.highestRiskLevel) riskRating = departure.highestRiskLevel;
    if (arrival.highestRiskLevel && arrival.highestRiskLevel > riskRating)
      riskRating = arrival.highestRiskLevel;
  }

  return (
    <Segment
      className={`flight-card ${flightCardClass}${
        isSelected ? " selected" : ""
      }`}
      key={operatingFlightNumber}
    >
      <div className="heading">
        <List horizontal>
          <List.Item>
            <Airline code={carrier} />
          </List.Item>
          <List.Item style={{ marginLeft: "0.5em" }}>
            <Header as="h5" data-aut="FlightCard|CarrierFightNumber">
              {carrier} {operatingFlightNumber}
            </Header>
          </List.Item>
          <List.Item style={{ marginLeft: "0.5em" }}>
            <b data-aut="FlightCard|DepartureAirportCode">
              {departure.airportCode}
            </b>
          </List.Item>
          <List.Item style={{ marginLeft: "0.5em", color: "red" }}>
            <PlaneIcon width="20px" height="20px" color="gray" />
          </List.Item>
          <List.Item style={{ marginLeft: "0.5em" }}>
            <b data-aut="FlightCard|ArrivalAirportCode">
              {arrival.airportCode}
            </b>
          </List.Item>
        </List>
        <List
          className="flight-card-top-right-corner"
          horizontal
          floated="right"
        >
          {/* This is for the upcoming flights version of the flightcard */}
          {renderPopup && (
            <List.Item>
              <FlightPopup
                departure={departure}
                arrival={arrival}
                passengers={passengers}
              />
              {passengers.length}
            </List.Item>
          )}
          {/* This is for the Traveler Tracker version of the flightcard*/}
          {passenger && passenger.email && (
            <List.Item style={{ marginLeft: "0.7em" }}>
              <a
                href={`mailto:${passenger.email}`}
                style={{ color: "initial" }}
                onClick={(e) => e.stopPropagation()}
              >
                <EmailIcon />
              </a>
            </List.Item>
          )}
        </List>
      </div>
      <div className="flight-card-checkin-row">
        {flight.checkInUrl && flight.referenceNumber && (
          <a
            onClick={(e) => e.stopPropagation()}
            href={flight.checkInUrl}
            target="_blank"
            rel="noreferrer"
            className="flight-card-passengers-checkin"
          >
            CHECK IN
          </a>
        )}
      </div>
      <div className="flight-card-passengers-container">
        <span data-aut="FlightCard|Passengers">{renderPassengers()}</span>
        {passenger.pnr ? (
          <span className="pnr" data-aut="FlightCard|PNR">
            PNR: {passenger.pnr}
          </span>
        ) : (
          flight.checkInUrl && (
            <a
              onClick={(e) => e.stopPropagation()}
              href={flight.checkInUrl}
              target="_blank"
              rel="noreferrer"
              className="flight-card-passengers-checkin"
            >
              CHECK IN
            </a>
          )
        )}
      </div>
      <div>
        <Divider style={{ margin: ".5rem 0rem" }} />
      </div>
      <Grid>
        <Grid.Row columns={3}>
          <Grid.Column>
            <List size="tiny">
              <List.Item>
                <List.Header>Departure</List.Header>
              </List.Item>
              <List.Item data-aut="FlightCard|DepartureDate">
                {formatDate(departure.localTime)}
              </List.Item>
              <List.Item data-aut="FlightCard|DepartureTime">
                {formatTime(departure.localTime)}
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List size="tiny">
              <List.Item>
                <List.Header>Arrival</List.Header>
              </List.Item>
              <List.Item data-aut="FlightCard|ArrivalDate">
                {formatDate(arrival.localTime)}
              </List.Item>
              <List.Item data-aut="FlightCard|ArrivalTime">
                {formatTime(arrival.localTime)}
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List size="tiny">
              <List.Item>
                <List.Header>Status</List.Header>
              </List.Item>
              {getStatus(flight)}
              {riskRating > 0 ? renderRisk(riskRating) : null}
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

const mapStateToProps = (state) => ({
  flightStatusEnum: state.application.flightStatusEnum,
});

export default connect(mapStateToProps)(FlightCard);
