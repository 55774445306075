import { Icon, Menu } from "semantic-ui-react";

interface TablePaginationI {
  page: number;
  firstIndex: number;
  lastIndex: number;
  rowsPerPage: number;
  setPage: (page: number) => void;
  count: number;
}

function TablePagination({
  page,
  firstIndex,
  lastIndex,
  rowsPerPage,
  setPage,
  count,
}: TablePaginationI) {
  function getFirstPage() {
    let checkPage = page;
    let firstPage = 1;
    while (checkPage >= 5) {
      firstPage += 4;
      checkPage -= 4;
    }
    return firstPage;
  }

  const firstPage = getFirstPage();
  const secondPage = firstPage + 1;
  const thirdPage = firstPage + 2;
  const fourthPage = firstPage + 3;

  const pages = count > rowsPerPage ? Math.ceil(count / rowsPerPage) : 1;

  return (
    <div className="pagination-container">
      <span className="pagination-text">
        {count
          ? `${firstIndex + 1} - ${Math.min(lastIndex, count)} of ${count}`
          : "0 - 0 of 0"}
      </span>
      <Menu pagination size="small">
        <Menu.Item
          as="button"
          aria-label="Move Pages Left"
          icon
          onClick={() => {
            if (firstPage !== 1) setPage(page - 4);
          }}
          className="unstyled-button"
        >
          <Icon
            name="chevron left"
            className={firstPage === 1 ? "disabled-icon" : null}
          />
        </Menu.Item>
        <Menu.Item
          as="button"
          aria-label={firstPage}
          className={
            page === firstPage
              ? "selected-page unstyled-button"
              : "unstyled-button"
          }
          onClick={() => setPage(firstPage)}
        >
          {firstPage}
        </Menu.Item>
        <Menu.Item
          as="button"
          aria-label={secondPage}
          className={
            page === secondPage
              ? "selected-page unstyled-button"
              : "unstyled-button"
          }
          onClick={() => {
            if (secondPage <= pages) setPage(secondPage);
          }}
        >
          {secondPage <= pages ? secondPage : ""}
        </Menu.Item>
        <Menu.Item
          as="button"
          aria-label={thirdPage}
          className={
            page === thirdPage
              ? "selected-page unstyled-button"
              : "unstyled-button"
          }
          onClick={() => {
            if (thirdPage <= pages) setPage(thirdPage);
          }}
        >
          {thirdPage <= pages ? thirdPage : ""}
        </Menu.Item>
        <Menu.Item
          as="button"
          aria-label={fourthPage}
          className={
            page === fourthPage
              ? "selected-page unstyled-button"
              : "unstyled-button"
          }
          onClick={() => {
            if (fourthPage <= pages) setPage(fourthPage);
          }}
        >
          {fourthPage <= pages ? fourthPage : ""}
        </Menu.Item>
        <Menu.Item
          as="button"
          aria-label="Move Pages Right"
          className="unstyled-button"
          icon
          onClick={() => {
            // Make sure there are pages beyond our current set
            if (firstPage + 4 <= pages) {
              // Make sure we don't set our selected page outside of the range of pages we have available
              setPage(Math.min(page + 4, pages));
            }
          }}
        >
          <Icon
            name="chevron right"
            className={firstPage + 4 > pages ? "disabled-icon" : null}
          />
        </Menu.Item>
      </Menu>
    </div>
  );
}

export default TablePagination;
