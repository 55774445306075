import React, { Fragment, useCallback, useRef } from "react";
import { useOnMount, useSafeSetState } from "src/common/hooks";
import { Button } from "semantic-ui-react";
import "./Accordion.less";
import ArrowIcon from "src/icons/ArrowIcon";

interface AccordionI {
  centerText?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  leftIcon?: boolean;
  topContent: string;
  className?: string;
  isOpen?: boolean;
  renderButton?: boolean;
}

function Accordion({
  centerText,
  children,
  disabled,
  leftIcon,
  topContent,
  className,
  isOpen,
  renderButton,
}: AccordionI) {
  const [{ open, height }, safeSetState] = useSafeSetState({
    open: isOpen,
    height: isOpen ? "auto" : "0",
  });

  const handleToggle = useCallback(
    (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (disabled) return;
      if (e && e.stopPropagation) e.stopPropagation();
      // In order to have support a transition animation, we need hard coded pixel values for height, but we want to have it be auto as well for responsiveness.
      // The solution is to dynamically change that property before and after transitions.
      const newValue = !open;
      // Get the current height of the dom element, even if its set to 0, this is the rendered size of the content
      const scrollHeight = `${contentRef.current.scrollHeight}px`;
      let timeout;

      if (newValue) {
        // If we are opening up the accordion, we want to set the height to the scroll height
        safeSetState({ open: newValue, height: scrollHeight });

        // When the animation is done, we switch the style on the dom element
        timeout = setTimeout(() => {
          contentRef.current.style.height = "auto";
        }, transitionTime);
      } else {
        // Clear any timeouts that may be about to happen setting the height to auto
        clearInterval(timeout);
        // Set the height of the element to the scrollheight, it should be currently set to 'auto'
        contentRef.current.style.height = scrollHeight;
        // In order to make sure React waits long enough for the dom element to be updated, we wait for an animation frame
        window.requestAnimationFrame(() =>
          safeSetState({ open: newValue, height: "0px" })
        );
      }
    },
    [disabled, open, safeSetState]
  );

  useOnMount(() => {
    if (isOpen !== open) {
      handleToggle();
    }
  });

  const contentRef = useRef(null);
  const transitionTime = 300;

  return (
    <Fragment>
      <div
        className={`smart-accordion${className ? ` ${className}` : ""}`}
        aria-expanded={open}
      >
        <div
          className={`accordion-header-container${centerText ? " centered" : ""}
          ${disabled ? " disabled" : ""}`}
          onClick={handleToggle}
        >
          <h6
            className={`accordion-header${open ? "" : " closed"}${
              centerText ? " centered" : ""
            }`}
          >
            {leftIcon ? leftIcon : null}
            {topContent}
          </h6>
          {renderButton && (
            <Button className="toggle-button" icon>
              <ArrowIcon
                width="16px"
                className={`arrow-dropdown${open ? "" : " closed"}`}
              />
            </Button>
          )}
        </div>
        <div
          className="accordion-content"
          ref={contentRef}
          style={{
            height,
            transition: `height ${transitionTime}ms ease`,
          }}
        >
          {children}
        </div>
      </div>
    </Fragment>
  );
}

Accordion.defaultProps = {
  renderButton: true,
};

export default Accordion;
