import { Fragment, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { toggleLightMode } from "src/redux/actions";
import { setLightModePrefLocalStorage } from "src/services/application/";
import { Image, Divider, Popup, Icon, Radio } from "semantic-ui-react";
import { debounce, setGoogleAnalyticsEvent } from "src/common/helpers";
import { redirectToLogout } from "src/redux/actions/application";
import { useHistory } from "react-router";
import ImpersonationForm from "./ImpersonationForm";
import { handleSSORedirect } from "src/services/ssoRedirect";

/** User Profile for header with dropdown menu */

const renderAvatar = (user) => {
  if (
    user.givenName &&
    user.givenName.length &&
    user.familyName &&
    user.familyName.length
  ) {
    return (
      <span className="avatar">
        <span>{`${user.givenName[0]}${user.familyName[0]}`}</span>
      </span>
    );
  }

  if (user.picture) {
    return (
      <Image
        src={user.picture}
        avatar
        className="avatar"
        style={{ height: "40px", width: "40px" }}
      />
    );
  }
};
const Profile = ({
  toggleLightMode,
  user,
  globalSsoLinks,
  lightMode,
  initializeImpersonation,
  clearImpersonation,
  isImpersonating,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openRef = useRef(false);
  const history = useHistory();

  const handleToggle = (e, val) => {
    if (e) e.nativeEvent.stopImmediatePropagation();
    setGoogleAnalyticsEvent({
      action: "portal_dashboard_theme_switch",
      category: val ? "breezy" : "galaxy",
    });
    toggleLightMode(val);
    setLightModePrefLocalStorage(val);
  };
  const handleOpen = (e) => {
    if (e) {
      e.nativeEvent.stopImmediatePropagation();
    }
    const newState = !isOpen;
    setIsOpen(newState);
    openRef.current = newState;
  };
  const closeMenu = () => {
    if (isOpen) {
      setIsOpen(false);
      openRef.current = false;
    }
  };
  const debouncedClose = debounce(closeMenu, 50);

  const renderTrigger = () => {
    const currUser = user || {};
    if (!currUser.givenName) {
      return (
        <button className="unstyled-button" data-aut="UserProfile">
          <Icon name="caret down" onClick={handleOpen} />
        </button>
      );
    }
    const name = currUser.familyName
      ? `${currUser.givenName} ${currUser.familyName}`
      : currUser.givenName;

    return (
      <button
        className="unstyled-button"
        id="user-profile"
        onClick={handleOpen}
        data-aut="UserProfile"
      >
        {renderAvatar(currUser)}
        <span className="greeting">{name}</span> <Icon name="caret down" />
      </button>
    );
  };

  const renderGlobalSsoLinks = () => {
    return globalSsoLinks.map((link) => {
      const url = link.isDirectLink ? link.href : undefined;
      return (
        <Fragment key={link.id}>
          <a
            href={url}
            target={link.openInNewWindow ? "_blank" : undefined}
            rel="noreferrer"
            data-aut={`Profile|SSOLink|${link.label}`}
            onClick={() => {
              if (!link.isDirectLink)
                handleSSORedirect(link, history, isImpersonating);
              setGoogleAnalyticsEvent({
                action: "portal_dashboard_global_sso",
                category: link.identifier,
              });
            }}
          >
            {link.label}
          </a>
          <Divider />
        </Fragment>
      );
    });
  };

  function PopupContent() {
    const logoutRef = useRef(null);

    useEffect(() => {
      document.addEventListener("click", closeMenu);
      document.body.addEventListener("scroll", debouncedClose);
      window.addEventListener("keydown", checkTab, true);
      return () => {
        document.removeEventListener("click", closeMenu);
        document.body.removeEventListener("scroll", debouncedClose);
        window.removeEventListener("keydown", checkTab);
      };
    }, []);

    const checkTab = ({ key }) => {
      if (
        openRef.current &&
        key === "Tab" &&
        document.activeElement === logoutRef.current
      ) {
        setIsOpen(false);
        openRef.current = false;
      }
    };
    return (
      <Popup.Content
        className="profile-menu"
        onClick={(e) => e.stopPropagation()}
      >
        <h5>Select Screen Theme</h5>

        <Radio
          tabIndex={0}
          label="Breezy"
          checked={lightMode}
          className="lightmode-radio"
          onChange={(e) => handleToggle(e, true)}
          data-aut="Profile|LightMode|Breezy"
        />

        <Radio
          tabIndex={0}
          label="Galaxy"
          checked={!lightMode}
          className="lightmode-radio"
          onChange={(e) => handleToggle(e, false)}
          data-aut="Profile|LightMode|Galaxy"
        />

        <Divider />
        {Boolean(user.canImpersonate || isImpersonating) && (
          <ImpersonationForm
            initializeImpersonation={initializeImpersonation}
            clearImpersonation={clearImpersonation}
            isImpersonating={isImpersonating}
          />
        )}
        {globalSsoLinks.length ? renderGlobalSsoLinks() : null}
        <button
          data-aut="Profile|Logout"
          onClick={() => history.push("/logout")}
          ref={logoutRef}
          className="unstyled-button"
        >
          Log Out
        </button>
      </Popup.Content>
    );
  }

  return (
    <Popup trigger={renderTrigger()} open={isOpen} offset={[9, 0]}>
      {isOpen ? <PopupContent /> : <div />}
    </Popup>
  );
};

const mapStateToProps = (state) => {
  return {
    lightMode: state.application.lightMode,
    user: state.user.data,
    loading: state.user.loading,
    globalSsoLinks: state.globalSsoLinks,
    isImpersonating: state.impersonation.isImpersonating,
  };
};

const mapDispatchToProp = (dispatch) => ({
  redirectToLogout: () => dispatch(redirectToLogout()),
  toggleLightMode: (bool) => dispatch(toggleLightMode(bool)),
});

export default connect(mapStateToProps, mapDispatchToProp)(Profile);
