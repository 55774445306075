export const AGENT_CHAT = "AGENT_CHAT";
export const AGENT_CONTACT = "AGENT_CONTACT";
export const AIRLINES = "AIRLINES";
export const AIRPORTS = "AIRPORTS";
export const APPLICATION = "APPLICATION";
export const APPROVE = "APPROVE";
export const APPROVE_CSV = "APPROVE_CSV";
export const APPROVE_DELEGATE = "APPROVE_DELEGATE";
export const BI = "BI";
export const BUYRECOMMENDATION = "BUYRECOMMENDATION";
export const COLOR_HEADERFOOTER = "COLOR_HEADERFOOTER";
export const COLOR_PRIMARY = "COLOR_PRIMARY";
export const COMPANY_FEATURES = "COMPANY_FEATURES";
export const CONTACT_INFO = "CONTACT_INFO";
export const COUNTRIES = "COUNTRIES";
export const COUNTRY_RISK_REPORT = "COUNTRY_RISK_REPORT";
export const CURRENCIES = "CURRENCIES";
export const CURRENCIES_TIME_SERIES = "CURRENCIES_TIME_SERIES";
export const DATE_FORMAT = "MM/DD/YYYY";
export const DECREMENT_ACTIVE_SESSION = "DECREMENT_ACTIVE_SESSION";
export const FAREFORECASTER = "FAREFORECASTER";
export const FLIGHTDETAILS = "FLIGHTDETAILS";
export const GLOBAL_SSO = "GLOBAL_SSO";
export const IMPERSONATION = "IMPERSONATION";
export const IMPERSONATION_ID = "IMPERSONATION_ID";
export const IMPERSONATION_TOKEN = "IMPERSONATION_TOKEN";
export const INVOICES = "INVOICES";
export const ISO_DATE_FORMAT = "YYYY-MM-DD";
export const ISO_TIME_FORMAT = "hh:mm:ss";
export const ITINERARYDETAILS = "ITINERARYDETAILS";
export const LIGHT_MODE_PREF = "LIGHT_MODE_PREF";
export const LINKS = "LINKS";
export const LOG_UI_ERROR = "LOG_UI_ERROR";
export const MICROSITE_COUNTRIES = "MICROSITE_COUNTRIES";
export const MICROSITE_COUNTRY_RISK_REPORT = "MICROSITE_COUNTRY_RISK_REPORT";
export const NEWS = "NEWS";
export const OBTS = "OBTS";
export const RISK_STATE_REPORT = "RISK_STATE_REPORT";
export const SELF_REGISTRATION = "SELF_REGISTRATION";
export const SSO = "SSO";
export const SSO_REDIRECT_URL = "SSO_REDIRECT_URL";
export const STATE = "STATE";
export const TIME_FORMAT = "hh:mm A";
export const TOOLS = "TOOLS";
export const TRAVEL_RESTRICTIONS = "TRAVEL_RESTRICTIONS";
export const UNUSED_TICKETS = "UNUSED_TICKETS";
export const UPDATE_MENU = "UPDATE_MENU";
export const WIDGET = "WIDGET";
export const WIDGETPROPERTIES = "WIDGETPROPERTIES";

// Risk Levels
export const EXTREME_RISK = "EXTREME";
export const HIGH_RISK = "HIGH";
export const LOW_RISK = "LOW";
export const MODERATE_RISK = "MODERATE";
export const TRIVIAL_RISK = "TRIVIAL";
export const UNKNOWN_RISK = "UNKNOWN";

/**
 * Access key to use the Mapbox API with.
 */

export const MAPBOX_STYLE =
  "mapbox://styles/jorgeagraz/cke37gog8041k19q5m69ybe25";

export const MAPBOX_STYLE_LIGHT =
  "mapbox://styles/jorgeagraz/cke37gte103z11ao91gcbjce2";

export const MAPBOX_TOKEN =
  "pk.eyJ1Ijoiam9yZ2VhZ3JheiIsImEiOiJjamdlY3FmaGIzZ2Q5Mnh0NTJhbGoyYTlrIn0.YauNIswHnJzJdLlbO-o_XA";
