import { useState } from "react";
import PropTypes from "prop-types";
import AgentChat from "./AgentChat";
import Header from "./Header";
import Footer from "./Footer";
import AsideMenu from "./AsideMenu";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import {
  fetchApplicationAndDependencies,
  routeChanged,
  setAsideMenuCondensed,
  setAsideMenuCondensedPref,
} from "src/redux/actions";
import Routes from "../Routes";
import ErrorBoundary from "src/compositions/ErrorBoundary/index.js";
import { useEffect } from "react";
import MinimizeArrowsIcon from "src/icons/MinimizeArrowsIcon";
import { LinkI } from "src/types/Link";
import { ContactI } from "src/redux/reducers/contactInfo";
import { ApplicationStateI } from "src/redux/reducers/application";
import { UserState } from "src/redux/reducers/user";
import ImpersonationModal from "./ImpersonationModal";
import { ImpersonationStateI } from "src/redux/reducers/impersonation";
import { useImpersonation } from "src/common/hooks/impersonation";
import { useSessionTimeout } from "src/common/hooks/sessionTimeout";
import { setGoogleAnalyticsEvent } from "src/common";

const SmartApp = ({
  agentChat,
  contactInfo,
  headerState,
  history,
  load,
  loading,
  lightMode,
  location,
  impersonation,
  redirectToLogout,
  setAsideMenuCondensed,
  setAsideMenuCondensedPref,
  user,
  portalState,
  asideMenuCondensed,
  asideMenuCondensedPref,
  routeLocationChanged,
}) => {
  const [isChatOpen, setChatOpen] = useState(false);
  const { initializeImpersonation, clearImpersonation } = useImpersonation();
  const { initializeSessionTimeout } = useSessionTimeout(user);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (user.roles) {
      initializeSessionTimeout();
    }
  }, [user.roles]);

  useEffect(() => {
    const payload = { location: window.location };
    routeLocationChanged(payload);
  }, [window.location.pathname]);

  const handleChatOpen = () => {
    setGoogleAnalyticsEvent({
      action: "portal_dashboard_agent_chat_click",
      category: (!isChatOpen).toString(),
    });
    setChatOpen(!isChatOpen);
  };

  const handleClick = () => {
    const newCondensed = !(asideMenuCondensed && asideMenuCondensedPref);
    setAsideMenuCondensed(newCondensed);
    // Update our condensed preference if we are not on the dashboard
    if (window.location.pathname !== "/")
      setAsideMenuCondensedPref(newCondensed);
  };

  const headerCondensed = headerState && headerState.isCondensed ? true : false;
  const { pageClassName } = portalState;
  const agentChatHref = agentChat && agentChat.href;

  /*
      Make sure that correct classname is applied to body for light/dark mode.
      We set the classname on the body instead of on a div inside of the app since
      some libraries such as react-sortable-hoc will render content outside of our app.
      This allows us to still have control over the styling of that content.
    */
  if (lightMode && document.body.className !== "light-mode")
    document.body.className = "light-mode";
  else if (!lightMode && document.body.className === "light-mode")
    document.body.className = "";
  if (redirectToLogout) return <Redirect to="/logout" />;
  const isCondensed = asideMenuCondensed && asideMenuCondensedPref;
  return (
    <ErrorBoundary addContainer addLayoutClass>
      <div
        className={`layout ${pageClassName} ${
          impersonation?.isImpersonating ? "impersonation" : ""
        }`}
        data-aut="Portal|Container"
      >
        <Header
          ctmLogo="/images/ctm-portal-logo-white.svg"
          handleChatOpen={handleChatOpen}
          agentChatLink={agentChat}
          initializeImpersonation={initializeImpersonation}
          clearImpersonation={clearImpersonation}
          isImpersonating={impersonation?.isImpersonating}
        />
        <main className="container">
          <input
            type="checkbox"
            id="toggle-aside"
            checked={!isCondensed}
            onClick={handleClick}
            readOnly
          />
          <div className="aside">
            <label className="toggle-aside" htmlFor="toggle-aside">
              <button
                className="unstyled-button"
                onClick={handleClick}
                data-aut={
                  isCondensed
                    ? "AsideToggleButton|Condensed"
                    : "AsideToggleButton|Expanded"
                }
              >
                <MinimizeArrowsIcon />
              </button>
            </label>
            <AsideMenu
              path={location.pathname}
              isCondensed={isCondensed}
              history={history}
              search={location.search}
              userLogo={user.logo}
            />
          </div>
          <ErrorBoundary addContainer pageView>
            <div
              className={
                headerCondensed ? "content condensed-header" : "content"
              }
            >
              <Routes user={user} loading={loading} location={location} />
            </div>
          </ErrorBoundary>
          {isChatOpen && (
            <AgentChat
              agentChatHref={agentChatHref}
              handleClose={handleChatOpen}
            />
          )}
        </main>
        {Boolean(impersonation?.isTimingOut || impersonation.isTimedOut) && (
          <ImpersonationModal
            isTimedOut={impersonation?.isTimedOut}
            clearImpersonation={clearImpersonation}
          />
        )}
        <Footer contactInfo={contactInfo} />
      </div>
    </ErrorBoundary>
  );
};

SmartApp.propTypes = {
  user: PropTypes.object,
  portalState: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  redirectToLogout: PropTypes.bool,
};

export default connect(
  (state: {
    agentChat: LinkI;
    contactInfo: ContactI;
    application: ApplicationStateI;
    user: UserState;
    impersonation: ImpersonationStateI;
  }) => {
    return {
      agentChat: state.agentChat,
      contactInfo: state.contactInfo,
      headerState: state.application.header,
      lightMode: state.application.lightMode,
      user: state.user.data,
      portalState: state.application.portal,
      loading: state.user.loading,
      redirectToLogout: state.application.redirectToLogout,
      asideMenuCondensed: state.application.asideMenuCondensed,
      asideMenuCondensedPref: state.application.asideMenuCondensedPref,
      impersonation: state.impersonation,
    };
  },
  (dispatch) => ({
    load: () => dispatch(fetchApplicationAndDependencies()),
    setAsideMenuCondensedPref: (payload) =>
      dispatch(setAsideMenuCondensedPref(payload)),
    setAsideMenuCondensed: (payload) =>
      dispatch(setAsideMenuCondensed(payload)),
    routeLocationChanged: (payload) => dispatch(routeChanged(payload)),
  })
)(SmartApp);
