import { useEffect, useCallback } from "react";
import "./SearchInput.less";
import { Search } from "semantic-ui-react";

import { DebouncedFunction, debounce } from "../../common/helpers";
import { useSafeSetState } from "src/common/hooks";
import LocationIcon from "src/icons/LocationIcon";

/**
 * Extended version of the Semantic Search Dropdown with our UI customization added.
 *  */

interface SearchInputI {
  className: string;
  defaultValue: string;
  dataAut: string;
  fluid?: boolean;
  handleSearch: (val: string) => Promise<unknown[]>;
  mapResults?: (vals: unknown[]) => { title: string; name: string }[];
  minCharacters?: number;
  placeholder?: string;
  renderIcon?: boolean;
  reset: () => void;
  resultRenderer: (item: { title: string; name: string }) => JSX.Element;
  setSelection: (item: { title: string; name: string; value?: string }) => void;
}

const SearchInput = ({
  className,
  defaultValue,
  dataAut,
  fluid,
  handleSearch,
  mapResults,
  minCharacters,
  placeholder,
  renderIcon,
  reset,
  resultRenderer,
  setSelection,
}: SearchInputI) => {
  const initialState = {
    searching: false,
    loading: false,
    options: [],
    value: null,
    inputValue: defaultValue || "",
  };

  const [state, safeSetState] = useSafeSetState(initialState);

  useEffect(() => {
    if (defaultValue && defaultValue.length && !state.inputValue.length) {
      safeSetState({ inputValue: defaultValue });
    }
  }, [defaultValue]);

  // Only send out search requests every 300 milliseconds

  const debouncedSearch: DebouncedFunction = useCallback(
    debounce((value) => {
      handleSearch(value).then((data) => {
        // If data needs to be mapped, it is done via a passed in prop
        safeSetState({
          loading: false,
          options: mapResults ? mapResults(data) : data,
        });
      });
    }, 300),
    []
  );

  // Clears our component state when the users input is empty
  const resetComponent = () => {
    safeSetState({ loading: false, options: [], value: null, inputValue: "" });
  };

  // Event handler for user clicking on an option.
  // The set selection prop is used here for parent components to get access to the selection.
  const handleResultSelect = (e, { result }) => {
    safeSetState({
      value: result,
      loading: false,
      inputValue: result.title,
      searching: false,
    });
    if (setSelection) setSelection(result);
  };

  const initiateNewSearch = (value) => {
    if (!value || value.length < minCharacters) {
      safeSetState({
        value: null,
        inputValue: value,
        searching: false,
        loading: false,
      });
    } else {
      safeSetState({
        loading: true,
        value: null,
        inputValue: value,
        searching: true,
      });
      debouncedSearch(value);
    }
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  // When a user types in a new search, we need to be able to clear out any previously selected data
  const handleSearchChange = (e, { value }) => {
    // Reset parents data if reset prop is provided
    if (reset) reset();
    // If our new value is empty, reset component
    if (value.length < 1 || value.indexOf(" ") === 0) resetComponent();
    else initiateNewSearch(value);
  };

  const { inputValue, loading, options, searching } = state;

  return (
    <Search
      className={`search-input ${className || ""}`}
      fluid={fluid}
      input={
        renderIcon
          ? {
              icon: <LocationIcon className="icon" />,
              iconPosition: "left",
            }
          : {}
      }
      loading={loading}
      minCharacters={minCharacters}
      open={inputValue.length > 0 && searching}
      onBlur={() => safeSetState({ loading: false })}
      onFocus={handleFocus}
      onResultSelect={handleResultSelect}
      onSearchChange={handleSearchChange}
      placeholder={placeholder || ""}
      resultRenderer={resultRenderer || undefined}
      selectFirstResult={true}
      results={options}
      value={inputValue}
      data-aut={dataAut || undefined}
    />
  );
};

SearchInput.defaultProps = {
  renderIcon: true,
  minCharacters: 1,
};

export default SearchInput;
